import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext } from '@thd-olt-functional/customer-information';
import { LayoutRenderer } from '@thd-olt-component-react/layout-renderer';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { useLocation } from '@thd-olt-component-react/router';
import { useUrlTmxId } from '../../hooks/useUrlTmxId';
import useStorefront from '../../hooks/useStorefront';
import {
  CUSTOMER_TYPES,
  userAuthorizationStatus,
  getSvocId,
  getUserId,
  getMarketingCloudVisitorID,
  getGuestUserId,
  getHydrationOptions,
} from '../../utils';
import {
  proDataModelServerSide,
  proDataModelClientSide,
} from '../../datamodels/home';
import { components } from '../../components/components';
import { usePreviewByID } from '../../hooks';
import { HomepageProvider, HomepageContext } from '../../components/context';
import { componentOptions } from './component-options';
import { HomepageMetadataContainer } from '../../components/metadata/HomepageMetadataContainer';

let layoutDataModel = {
  server: extend({}, proDataModelServerSide),
  client: proDataModelClientSide
};

export const ExchangeHomepage = ({ slug, thdSEOBotDetection }) => {
  declareContexts([QueryContext, ExperienceContext, HomepageContext, CustomerContext], { freeze: true });
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const store = useStore({ varnish: false });
  const storefront = useStorefront({ skip: false });
  const userState = {
    userId: getSvocId() || getUserId(),
    mcvisID: getMarketingCloudVisitorID(),
    userAuthStatus: userAuthorizationStatus(),
    guestUserId: getGuestUserId(),
  };

  const contentfulPreviewId = usePreviewByID();
  const exchangeHPBackup = useConfigService('exchangeHPBackup') || '/c/homedepot';
  const { search: querystring, pathname } = useLocation();

  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewPage = pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  /**
   * This is not a class in the CSS sense. It's referring
   * to the type of layout we have in Contentful, which
   * at the time of writing seems to be a choice between
   * PersonalizedHomepage and EducationalLayout.
   */
  let layoutClass;

  if (contentfulPreviewId) {
    layoutDataModel = {
      server: proDataModelServerSide,
      client: proDataModelClientSide
    };
    layoutClass = 'PersonalizedHomePage';
  }

  const { guestUserId, mcvisID, userAuthStatus } = userState;

  useEffect(function triggerHomepageLoadEvent() {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'exchange-homepage.page-load',
      'homepage',
    );
  }, []);

  useEffect(function setThdOnlineChannelCookie() {
    if (channel === 'mobile' && !isConsumerApp) {
      const encodedValue = encodeURIComponent('E1=S2');
      window.cookieUtils.fed_CreateCookie(
        'THD_ONLINE_CHANNEL',
        encodedValue,
        1,
      );
    }
  }, []);

  return (
    <HomepageProvider
      value={{
        thdSEOBotDetection,
        hydrationOptions: getHydrationOptions(channel, isConsumerApp),
        customerType: CUSTOMER_TYPES.EXCHANGE,
        urlTmxId: useUrlTmxId(),
        guestUserId,
        mcvisID,
        store,
        userAuthStatus,
        storefront
      }}
    >
      <div
        className="sui-max-w-screen-2xl sui-m-auto sui-pb-12"
      >
        <LayoutRenderer
          components={components}
          componentOptions={componentOptions}
          slug={slug}
          contentfulPreviewId={contentfulPreviewId}
          layoutClass={layoutClass}
          layoutDataModel={layoutDataModel}
          onContentInformationApiError={() => {
            if (typeof window !== 'undefined') {
              window.location.replace(exchangeHPBackup);
            }
          }}
          ssr
          renderMetadata={({ preloadedImages, metadata }) => {
            return (
              <HomepageMetadataContainer
                isPreviewPage={isPreviewPage}
                preloadedImages={preloadedImages}
                metadata={metadata}
              />
            );
          }}
          customerType={CUSTOMER_TYPES.EXCHANGE}
        />
      </div>
    </HomepageProvider>
  );
};

ExchangeHomepage.dataModel = layoutDataModel;

ExchangeHomepage.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};
ExchangeHomepage.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};
ExchangeHomepage.displayName = 'ExchangeHomepage';
